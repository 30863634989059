<template>
  <div />
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
  },
  data() {
    return {
      page: 1,
      perPage: 25,
      pageSizeOptions: [25, 50, 75, 100],
      filterSelectedOptions: [],
      isError: false,
      searchFieldsString: null,
      searchString: null,
      startFilter: false,
      notFullyDate: false,
      companyId: 0,
      onlyTrashed: false,
      withTrashed: false,
      searchJoin: 'and',

      siteId: null,
    }
  },
  computed: {
    visibleFields() {
      return this.fields.filter(field => field.visible)
    },
  },
  watch: {
    filterSelectedOptions() {
      this.searchString = Object.entries(this.filterSelectedOptions).map(item => `${item[0]}:${item[1]}`).join(';')
      this.searchFieldsString = Object.entries(this.filterSelectedOptions).map(item => `${item[0]}`).join(':like;')
      if (this.$route.name === 'licence-list') {
        this.searchFieldsString = this.searchFieldsString.split(':=;').map(item => {
          if (item === 'created_at' || item === 'activation_at' || item === 'renewal_at' || item === 'expiry_at') {
            return `${item}:between`
          }
          return item
        }).join(':like;')
      }

      if (this.$route.name === 'company-list') {
        // add :like after the type.id if there is no :like after the type.id

        this.searchFieldsString = this.searchFieldsString.split(';').map(item => {
          if (item.includes('type.id') && !item.includes(':like')) {
            return `${item}:like`
          }
          return item
        }).join(';')
      }
    },
  },
  async mounted() {
    const {
      pageNum, perPage, orderBy, sortedBy, companyId,
    } = this.$route.query
    const {
      search, searchFields,
    } = this.$route.query
    this.page = pageNum ?? 1
    this.perPage = perPage ?? 25
    this.companyId = companyId ?? 0
    const params = {
      ...this.$route.query,
    }
    Object.keys(params).forEach(k => (params[k] === '' || !params[k]) && delete params[k])

    if (orderBy || sortedBy) {
      await this.fetchtableData({
        ...params,
      })
    } else {
      await this.fetchtableData({
        pageNum: this.page, perPage: this.perPage, companyId: this.companyId, search, searchFields,
      })
    }
    this.setSearchParams(search, searchFields)
    this.setOrderBy(orderBy)
    this.setSortedBy(sortedBy)
  },
  methods: {
    /**
     * @params {number} pageNum
     */
    showModal(data) {
      this.$refs.modalSoftDelete.data = data
      this.$refs.modalSoftDelete.show()
    },
    clearAllData() {
      this.page = 1
      this.perPage = 25
      this.filterSelectedOptions = []
      this.searchString = null
      this.searchFieldsString = null
      this.companyId = 0
      this.fields = this.fields.map(item => {
        if (item && Object.prototype.hasOwnProperty.call(item, 'filterOptions')) {
          return { ...item, filterOptions: { ...item.filterOptions, filterValue: '' } }
        }
        return item
      })
      this.setSearchFields([])
      this.setOrderBy('')
      this.setSortedBy('')
      this.$router.push({
        name: this.$route.name,
      }).catch(() => {})
    },
    isFilterChanged(newFilters) {
      const filters = Object.fromEntries(
        Object.entries(newFilters).filter(([, value]) => value),
      )
      const oldFilters = Object.values(this.filterSelectedOptions).reduce((acc, item) => ({ ...acc, ...item }), {})

      const keysChanged = Object.keys(filters).length !== Object.keys(oldFilters).length
      const valuesChanged = !Object.keys(filters).every(
        key => filters[key] === oldFilters[key],
      )

      if (keysChanged || valuesChanged) {
        return true
      }

      return false
    },
    columnFilterFn(params, mobile = false) {
      if (this.startFilter || this.notFullyDate || !this.isFilterChanged(params.columnFilters)) {
        return
      }
      const searchValue = { ...params.columnFilters }
      const newObject = {}
      this.fields.forEach(item => {
        // eslint-disable-next-line no-prototype-builtins
        if (params.columnFilters.hasOwnProperty(item.field)) {
          // eslint-disable-next-line no-param-reassign
          item.filterOptions.filterValue = params.columnFilters[item.field]
        }
      })
      Object.keys(searchValue).forEach(key => {
        if (searchValue[key]) {
          newObject[key] = searchValue[key]
        }
      })
      this.filterSelectedOptions = { ...newObject }
      if (Object.values(this.filterSelectedOptions).length > 0) {
        this.startFilter = true
        this.$nextTick(() => {
          this.searchRows(mobile)
        })
      } else if (!this.isLoading) {
        this.resetFilter()
      }
    },
    async fetchtableData({
      pageNum = this.pagination.current_page,
      perPage = this.pagination.per_page,
      searchFields = this.searchFields,
      search = this.search,
      orderBy = this.orderBy,
      sortedBy = this.sortedBy,
      companyId = this.companyId,
      onlyTrashed = this.onlyTrashed,
      withTrashed = this.withTrashed,
      siteId = this.siteId,
      searchJoin = this.searchJoin,
    }) {
      const params = {
        page: pageNum,
        perPage,
        search,
        searchFields,
        orderBy,
        sortedBy,
        searchJoin,
        companyId,
        onlyTrashed,
        withTrashed,
      }
      if (siteId) {
        params.siteId = siteId
      }
      this.isLoading = true

      Object.keys(params).forEach(k => (params[k] === '' || !params[k]) && delete params[k])

      try {
        await this.fetchItems({
          ...params,
        })

        this.perPage = perPage
        this.page = pageNum
        this.isError = false
      } catch (error) {
        const { status } = error.response
        this.isError = true
        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.isLoading = false
        this.$nextTick(() => {
          this.startFilter = false
        })
      }
    },
    /**
     * @params {number} pageNum
     */
    async changePage(pageNum) {
      if (this.$refs.VueGoodTable && this.$refs.VueGoodTable.selectedRow) {
        this.this.$refs.VueGoodTable.selectedRow = []
      }
      if (pageNum !== this.pagination.current_page) {
        const params = {
          pageNum,
          perPage: this.pagination.per_page,
          searchFields: this.searchFieldsString,
          search: this.searchString,
          orderBy: this.orderBy,
          sortedBy: this.sortedBy,
          searchJoin: 'and',
        }

        Object.keys(params).forEach(k => (params[k] === '' || !params[k]) && delete params[k])

        await this.fetchtableData(params)

        this.redirectByParams(params)
      }
    },
    /**
     * @params {number} currentPerPage
     */
    async perPageChanged(currentPerPage) {
      if (currentPerPage !== this.pagination.per_page) {
        await this.fetchtableData({ pageNum: 1, perPage: currentPerPage })
        const params = {
          pageNum: this.pagination.current_page,
          perPage: currentPerPage,
          searchFields: this.searchFieldsString,
          search: this.searchString,
          orderBy: this.orderBy,
          sortedBy: this.sortedBy,
          searchJoin: 'and',
        }

        Object.keys(params).forEach(k => (params[k] === '' || !params[k]) && delete params[k])

        this.redirectByParams(params)
      }
    },
    async searchRows(mobile = false) {
      const params = {
        pageNum: 1,
        perPage: this.perPage,
        searchFields: this.searchFieldsString,
        search: this.searchString,
        orderBy: this.orderBy,
        sortedBy: this.sortedBy,
        searchJoin: mobile ? 'or' : this.searchJoin,
        companyId: this.companyId ?? 0,
      }

      Object.keys(params).forEach(k => (params[k] === '' || !params[k]) && delete params[k])
      await this.fetchtableData({
        ...params,
      })

      this.redirectByParams(params)
    },
    async onSortChange(sortEl) {
      if (this.startFilter) {
        return
      }
      const orderBy = sortEl[0].field
      const sortedBy = sortEl[0].type

      if (orderBy !== this.orderBy || sortedBy !== this.sortedBy) {
        const params = {
          pageNum: this.page,
          perPage: this.perPage,
          searchFields: this.searchFieldsString,
          search: this.searchString,
          orderBy,
          sortedBy,
          searchJoin: 'and',
        }

        Object.keys(params).forEach(k => (params[k] === '' || !params[k]) && delete params[k])
        this.startFilter = true
        await this.fetchtableData({
          ...params,
        })
        this.redirectByParams(params)
      }
    },
    redirectByParams(params) {
      setTimeout(() => {
        this.$router.push({
          name: this.$route.name,
          query: {
            ...params,
          },
        }).catch(() => {})
      }, 1)
    },
    /**
     * @params {number} id
     */
    toEdit(id) {
      // without setTimeout we will have scroll on table because dropdown can`t handle @hide event
      setTimeout(() => {
        this.$router.push({ name: this.uppdateRouteName, params: { id } })
      }, 0)
    },
    /**
     * @params {number} id
     */
    async deleteRow(id) {
      try {
        await this.deleteRecord(id)

        if (this.items.length === 0) {
          if (this.pagination.current_page !== 1 && this.pagination.last_page !== 1) {
            await this.fetchtableData({
              pageNum: this.pagination.current_page - 1,
              onlyTrashed: this.onlyTrashed,
              withTrashed: this.withTrashed,
            })
          }
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        const { status } = error.response

        if (status === 422) {
          let detailContent = ''

          if (error?.response?.data?.fields) {
            detailContent += 'You cannot remove this item because the entity has the following relation(s):<br>\n'
            Object.keys(error.response.data.fields).forEach(field => {
              detailContent += ` - ${field}: \n 
                <ul class="mb-1">
                  ${error.response.data.fields[field].map(item => `<li>${item}</li>`).join('')}
                  </ul>`
            })
          }

          this.showModal({
            title: 'Validation error',
            content: detailContent,
            icon: 'BellIcon',
            variant: 'danger',
          })
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: 'Validation error',
          //     content: detailContent,
          //     icon: 'BellIcon',
          //     variant: 'danger',
          //   },
          // })
          return {
            title: 'Validation error',
            content: detailContent,
            icon: 'BellIcon',
            variant: 'danger',
          }
        }

        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
      return {}
    },
    setSearchParams(search, fields) {
      this.setSearchFields(fields)

      this.filterSelectedOptions = []

      if (!fields) {
        return
      }
      this.filterSelectedOptions = search.split(';').map(field => {
        const item = field.split(':')
        const obj = {}
        // eslint-disable-next-line prefer-destructuring
        obj[item[0]] = item[1]
        return obj
      })
      for (let i = 0; i <= this.fields.length; i += 1) {
        if (this.fields[i] && Object.prototype.hasOwnProperty.call(this.fields[i], 'filterOptions')) {
          this.filterSelectedOptions.forEach(item => {
            if (Object.keys(item)[0] === this.fields[i].field) {
              // eslint-disable-next-line prefer-destructuring
              this.fields[i].filterOptions.filterValue = Object.values(item)[0]
            }
          })
        }
      }
    },
    async resetFilter(data) {
      this.page = 1
      this.perPage = 25
      this.filterSelectedOptions = []
      this.searchString = null
      this.searchFieldsString = null
      this.companyId = 0
      this.fields = this.fields.map(item => {
        if (item && Object.prototype.hasOwnProperty.call(item, 'filterOptions')) {
          return { ...item, filterOptions: { ...item.filterOptions, filterValue: '' } }
        }
        return item
      })
      this.setSearchFields([])
      this.setOrderBy('')
      this.setSortedBy('')

      try {
        const payload = {
          page: 1,
          perPage: 25,
          searchFields: null,
          search: null,
          orderBy: null,
          sortedBy: null,
          onlyTrashed: this.onlyTrashed,
          withTrashed: this.withTrashed,
        }

        if (data?.siteId) {
          payload.siteId = data?.siteId
        }
        this.isLoading = true
        await this.fetchItems(payload)
      } catch (error) {
        const { status } = error.response
        this.isError = true
        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.isLoading = false
        this.$nextTick(() => {
          this.startFilter = false
        })
      }
      this.$router.push({
        name: this.$route.name,
      }).catch(() => {})
    },
    async toggleFilterOnlyTrashed() {
      this.page = 1
      this.perPage = 25
      this.filterSelectedOptions = []
      this.searchString = null
      this.searchFieldsString = null
      this.companyId = 0
      this.onlyTrashed = !this.onlyTrashed
      this.fields = this.fields.map(item => {
        if (item && Object.prototype.hasOwnProperty.call(item, 'filterOptions')) {
          return { ...item, filterOptions: { ...item.filterOptions, filterValue: '' } }
        }
        return item
      })
      this.setSearchFields([])
      this.setOrderBy('')
      this.setSortedBy('')
      try {
        this.isLoading = true
        await this.fetchItems({
          page: 1,
          perPage: 25,
          searchFields: null,
          search: null,
          orderBy: null,
          sortedBy: null,
          onlyTrashed: this.onlyTrashed,
        })
      } catch (error) {
        const { status } = error.response
        this.isError = true
        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.isLoading = false
        this.$nextTick(() => {
          this.startFilter = false
        })
      }
      this.$router.push({
        name: this.$route.name,
      }).catch(() => {})
    },
    async setFilterWithTrashed(value) {
      this.page = 1
      this.perPage = 25
      this.filterSelectedOptions = []
      this.searchString = null
      this.searchFieldsString = null
      this.companyId = 0
      this.withTrashed = value
      this.fields = this.fields.map(item => {
        if (item && Object.prototype.hasOwnProperty.call(item, 'filterOptions')) {
          return { ...item, filterOptions: { ...item.filterOptions, filterValue: '' } }
        }
        return item
      })
      this.setSearchFields([])
      this.setOrderBy('')
      this.setSortedBy('')
      try {
        this.isLoading = true
        await this.fetchItems({
          page: 1,
          perPage: 25,
          searchFields: null,
          search: null,
          orderBy: null,
          sortedBy: null,
          withTrashed: this.withTrashed,
        })
      } catch (error) {
        const { status } = error.response
        this.isError = true
        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.isLoading = false
        this.$nextTick(() => {
          this.startFilter = false
        })
      }
      this.$router.push({
        name: this.$route.name,
      }).catch(() => {})
    },
    async recoverRow(id) {
      try {
        await this.recoverRecord(id)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Recovered!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })

        await this.fetchtableData({
          pageNum: 1,
          onlyTrashed: this.onlyTrashed,
          withTrashed: this.withTrashed,
        })
      } catch (error) {
        const { status } = error.response

        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.RESET_STATE()

    next()
  },
}
</script>

<style lang="scss" scoped>

</style>
